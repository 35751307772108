export type Success<T> = {
  type: 'Success'
  value: T
}

export type Failure<E> = {
  type: 'Failure'
  error: E
}

export type Result<T, E> = Success<T> | Failure<E>

export function success<T>(value: T): Success<T> {
  return { type: 'Success', value }
}

export function failure<E>(error: E): Failure<E> {
  return { type: 'Failure', error }
}

export function mapError<T, E1 extends string, E2>(
  result: Result<T, E1>,
  errorMap: { [K in E1]: E2 }
): Result<T, E2> {
  if (result.type === 'Success') {
    return result
  } else {
    return failure(errorMap[result.error])
  }
}
export function mapValue<T1, T2, E>(
  result: Result<T1, E>,
  f: (t: T1) => T2
): Result<T2, E> {
  if (result.type === 'Success') {
    return success(f(result.value))
  } else {
    return result
  }
}

export function mapErrorF<T, E1, E2>(
  f: (e: E1) => E2
): (result: Result<T, E1>) => Result<T, E2> {
  return (result) => {
    if (result.type === 'Success') {
      return result
    } else {
      return failure(f(result.error))
    }
  }
}

export function mapValueF<T1, T2, E>(
  f: (t: T1) => T2
): (result: Result<T1, E>) => Result<T2, E> {
  return (result) => {
    if (result.type === 'Success') {
      return success(f(result.value))
    } else {
      return result
    }
  }
}

export function successAll<T, E>(results: Result<T, E>[]): Result<T[], E> {
  const values: T[] = []
  for (const result of results) {
    if (result.type === 'Success') {
      values.push(result.value)
    } else {
      return result
    }
  }
  return success(values)
}

export async function resultPromise<T, E>(
  a: Result<Promise<T>, E>
): Promise<Result<T, E>> {
  if (a.type === 'Failure') {
    return Promise.resolve(failure(a.error))
  } else {
    const t = await a.value
    return success(t)
  }
}

export function bindPromiseResult<T1, T2, E>(
  f: (t: T1) => Promise<Result<T2, E>>
): (a: Promise<Result<T1, E>>) => Promise<Result<T2, E>> {
  return async (a) => {
    const result = await a
    if (result.type === 'Failure') {
      return Promise.resolve(failure(result.error))
    } else {
      return await f(result.value)
    }
  }
}
